import React, { useState, useContext, useEffect } from "react";
import EventBus from "./EventBus";
import "./CashTransactions.css";
import { GetPaymentInitiationDetails,GeneratePaymentQRCode } from "./services/PaymentService";
import { GetEventsWithDjInfo } from "./services/EventsService";
import { useLoadingContext } from "./LoadingProvider";
import Swal from 'sweetalert2'; // Import SweetAlert2
import { fetchStaffByEventId } from "./services/UserService";


const CashTransactionForm = () => {
    const [events, setEvents] = useState([]);
    const [selectedEventId, setSelectedEventId] = useState("");
    const [fileUpload, setFileUpload] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedDjId, setSelectedDjId] = useState(null);
    const { setLoading } = useLoadingContext();
    const [eventError, setEventError] = useState(false);
    const [users, setUsers] = useState([]); // State to store the user data from the API
    const [qrCodeUrl, setQrCodeUrl] = useState(null);



    useEffect(() => {
        async function fetchEvents() {
            try {
                const res = await GetEventsWithDjInfo();
                setEvents(res); // Assuming the API returns the array directly
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        }
        fetchEvents();
    }, []);

    async function fetchStaff(selectedEventId){
        try{
            return await fetchStaffByEventId(selectedEventId);
        }
        catch(error){
            console.error("Error fetching staff:", error)
        }
    }

    useEffect(() => {
        if (users.length > 0) {
          console.log('Users have been updated:', users);
          // Place your logic here that should run after users are set
        }
      }, [users]);

    const handleEventChange = async (e) => {
        setSelectedEventId(e.target.value); // Update the selected event ID
        setEventError(false); // Clear error on selection

        const selectedEventId = e.target.value;

        const selectedEvent = events.find(event => event.id.toString() === selectedEventId); // Find the event by ID

        if (selectedEvent) {
            const selectedDjId = selectedEvent.djId; // Extract the DJ ID from the event
    
            setSelectedEventId(selectedEventId); // Update the selected event ID state
            setFormData({
                ...formData,
                eventId: selectedEventId,
                djId: selectedDjId, // Autofill DJ ID based on selected Event
            });
            //console.log(selectedEventId);
            const userList = await fetchStaff(selectedEventId);
            setUsers(userList);
            console.log(userList);
        }
    };

    //console.log(events);

    const [formData, setFormData] = useState({
        amount: "",
        userId: 0,
        totalAmount: "",
        eventId: "",
        djId: "",
        songId: "",
        songName: "",
        artistId: "",
        artistName: "",
        albumName: "",
        albumImage: "",
        cake: null,
        packages: null,
        referenceId: null,
        micAnnouncement: "",
        contact: "",
        ScreenAnnouncement: null,
        paymentMethod:""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const handleEventChange = (e) => {
    //     const selectedEventId = e.target.value;
    //     setFormData({
    //         ...formData,
    //         eventId: selectedEventId,
    //         //djId: djId, // Autofill DjId based on EventId
    //     });
    // };

    const handleCakeChange = (e) => {
        const { value, checked } = e.target;
        const cake = formData.cake ? JSON.parse(formData.cake) : {};
        if (checked) {
            cake[value] = 1;
        } else {
            delete cake[value];
        }
        const cakeStringified = Object.keys(cake).length > 0 ? JSON.stringify(cake) : null;

        setFormData({ ...formData, cake: cakeStringified });
    };

    const isNullOrEmpty = (value) => value === null || value === undefined || value.trim() === "" || value === '';

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate if event is selected
        console.log(selectedEventId);
        if (!selectedEventId) {
            console.log(selectedEventId);
            setEventError(true);
            return;
        }
        // if(formData.eventId){
        //     console.log(selectedEventId);
        //     setEventError(true);
        //     return;
        // }

        if (
            (formData.packages === "Silver" || formData.packages === "Gold") &&
            !formData.micAnnouncement.trim()
          ) {
            Swal.fire({
              title: "Validation Error",
              text: "Mic Announcement is mandatory for Silver and Gold packages.",
              icon: "error",
              confirmButtonText: "OK",
            });
            return;
          }

          if (
            (formData.packages === "VIP" ) &&
            (!formData.micAnnouncement.trim() ||
            !formData.uploadImg)
          ) {
            Swal.fire({
              title: "Validation Error",
              text: "Mic Announcement and photo is mandatory for VIP package.",
              icon: "error",
              confirmButtonText: "OK",
            });
            return;
          }
          console.log(formData.packages, formData.songName, formData.cake, formData.micAnnouncement);
          // Validation for amount with additional conditions
            if (
                !formData.packages && // Only validate when no package is selected
                !isNullOrEmpty(formData.totalAmount) && // Amount is entered
                isNullOrEmpty(formData.songName) &&
                isNullOrEmpty(formData.micAnnouncement) &&
                (!formData.cake || Object.keys(JSON.parse(formData.cake || "{}")).length === 0)
            ){
                Swal.fire({
                    title: "Validation Error",
                    text: "If you enter an amount, you must provide at least one of Song Name, Mic Announcement, or Cake.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                return;
            }

        setLoading(true);
        try{
            const formDataToSubmit = new FormData();
        console.log(formData);
        const referenceId = formData.referenceId || localStorage.getItem("referenceId");

        formDataToSubmit.append("amount", formData.totalAmount * 100);
        formDataToSubmit.append("userId", formData.userId);
        formDataToSubmit.append("TotalAmount", formData.totalAmount);
        formDataToSubmit.append("EventId", formData.eventId);
        formDataToSubmit.append("DjId", formData.djId);
        formDataToSubmit.append("SongId", formData.songId);
        formDataToSubmit.append("SongName", formData.songName);
        formDataToSubmit.append("ArtistId", formData.artistId);
        formDataToSubmit.append("ArtistName", formData.artistName);
        formDataToSubmit.append("AlbumName", formData.albumName);
        formDataToSubmit.append("AlbumImage", formData.albumImage);
        formDataToSubmit.append("Cake", formData.cake || null);
        formDataToSubmit.append("Packages", formData.packages || null);
        formDataToSubmit.append("ReferenceId", formData.referenceId);
        formDataToSubmit.append("Contact", formData.contact);

        if (formData.micAnnouncement) {
            formDataToSubmit.append("MicAnnouncement", formData.micAnnouncement);
        }
        formDataToSubmit.append("uploadImg", formData.uploadImg || null);
        if(formData.uploadImg){
            formDataToSubmit.append("ScreenAnnouncement", formData.micAnnouncement);
        }
        formDataToSubmit.append("IsCashRequest", true);


        console.log(formDataToSubmit);
        const res = await GetPaymentInitiationDetails(formDataToSubmit);
        console.log(res);
        setLoading(false);
        if (res && res.orderId) {
            // Show the SweetAlert2 popup if orderId is present in the response
            Swal.fire({
                title: 'Done',
                text: 'Cash payment successful!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        }

         // Reset the form after success
         setFormData({
            amount: "",
            userId: 0,
            totalAmount: "",
            eventId: "",
            djId: "",
            songId: "",
            songName: "",
            artistId: "",
            artistName: "",
            albumName: "",
            albumImage: "",
            cake: null,
            packages: null,
            referenceId: null,
            micAnnouncement: "",
            contact: "",
            ScreenAnnouncement: null
        });
        setSelectedEventId("");

        //const response = await GetPaymentInitiationDetails(formDataToSubmit);
        // Call API with payload
        //onSubmit(payload);
        }
        catch(ex){
            console.log(ex);
            setLoading(false);
        }
        
    };
    const handlePaymentMethodChange = async (e) => {
        const { value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            paymentMethod: value,
        }));

        // If UPI is selected, make the backend call to get the QR code
        if (value === 'UPI') {
            if(!formData.totalAmount){
                Swal.fire({
                    title: 'Error!',
                    text: `Please enter Amount`,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                return;
            }
            try {
                const response = await GeneratePaymentQRCode(formData.totalAmount)
                if (response) {
                    const imageBlob = await response.blob();
                    const imageUrl = URL.createObjectURL(imageBlob);
                    setQrCodeUrl(imageUrl);
                } else {
                    console.error('Failed to generate QR code');
                }
            } catch (error) {
                console.error('Error generating QR code:', error);
            }
        } else {
            setQrCodeUrl(null); // Clear QR code if not UPI
        }
    };

    const handleReferenceChange = (e) => {
        const selectedReferenceId = e.target.value;
        console.log('Selected Reference ID:', selectedReferenceId); // This will log the selected reference ID
        setFormData({ ...formData, referenceId: selectedReferenceId });
    };
    return (
        <form onSubmit={handleSubmit} className="zesty-cash-transaction-form">
            {showPopup && (
            <div className="upload-popup">
                <div className="popup-content">
                <div className="tick-icon">
                    <div className="circle"></div>
                    <div className="check"></div>
                </div>
                <p>File uploaded successfully!</p>
                </div>
            </div>
            )}
            {/* Event and DJ Section */}
            <div className="spicy-event-dj-container">
            <label className="fiery-label" htmlFor="eventId">Event</label>
                <select
                    className="crimson-input"
                    name="eventId"
                    value={selectedEventId}
                    onChange={handleEventChange}
                >
                    <option value="" disabled>Select an event</option>
                    {events.map((event) => (
                        <option key={event.id} value={event.id}>
                            {event.eventName}
                        </option>
                    ))}
                </select>
            </div>
            {/* Display error if validation fails */}
            {eventError && <span className="error-text" style={{color:"red"}}>Event is required.</span>}
            {/* <div className="spicy-event-dj-container">
                <label className="fiery-label" htmlFor="djId">DJ ID</label>
                <input className="crimson-input" type="text" name="djId" value={formData.djId} onChange={handleInputChange} placeholder="10043" />
            </div> */}

            {/* Song Details */}
            {/* <div className="melodic-container">
                <label className="fiery-label" htmlFor="songId">Song ID</label>
                <input className="crimson-input" type="text" name="songId" value={formData.songId} onChange={handleInputChange} />
            </div> */}
            <div className="melodic-container">
                <label className="fiery-label" htmlFor="songName">Song Name</label>
                <input className="crimson-input" type="text" name="songName" value={formData.songName} onChange={handleInputChange} />
            </div>
            {/* <div className="melodic-container">
                <label className="fiery-label" htmlFor="artistId">Artist ID</label>
                <input className="crimson-input" type="text" name="artistId" value={formData.artistId} onChange={handleInputChange} />
            </div>
            <div className="melodic-container">
                <label className="fiery-label" htmlFor="artistName">Artist Name</label>
                <input className="crimson-input" type="text" name="artistName" value={formData.artistName} onChange={handleInputChange} />
            </div>
            <div className="melodic-container">
                <label className="fiery-label" htmlFor="albumName">Album Name</label>
                <input className="crimson-input" type="text" name="albumName" value={formData.albumName} onChange={handleInputChange} />
            </div>
            <div className="melodic-container">
                <label className="fiery-label" htmlFor="albumImage">Album Image URL</label>
                <input className="crimson-input" type="url" name="albumImage" value={formData.albumImage} onChange={handleInputChange} />
            </div> */}

            {/* Optional Items */}
            <div className="delicious-cake-container">
                <label className="fiery-label">Cake</label>
                <div className="cake-option">
                    <input className="cake-checkbox" type="checkbox" value="Butterscotch" onChange={handleCakeChange} />
                    Butterscotch
                </div>
                <div className="cake-option">
                    <input className="cake-checkbox" type="checkbox" value="Choco-truffle" onChange={handleCakeChange} />
                    Choco-truffle
                </div>
            </div>

            <div className="package-container">
                <label className="fiery-label" htmlFor="packages">Packages</label>
                <select
                    className="package-select"
                    name="packages" 
                    value={formData.packages || ""}
                    onChange={handleInputChange}
                >
                    <option value="">Select a package</option>
                    <option value="Silver">Silver</option>
                    <option value="Gold">Gold</option>
                    <option value="VIP">VIP</option>
                </select>
            </div>

            {/* Reference ID */}
            {users && users.length > 0 && (
            <div className="reference-container">
                <label className="fiery-label" htmlFor="referenceId">
                    Reference ID
                </label>
                <select
                    className="crimson-input"
                    name="referenceId"
                    value={formData.referenceId || ""}
                    onChange={handleReferenceChange}
                >
                    <option value="" disabled>
                        Select a reference
                    </option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.name}
                        </option>
                    ))}
                </select>
            </div>
        )}


            {/* Amounts */}
            {/* <div className="amount-container">
                <label className="fiery-label" htmlFor="amount">Amount</label>
                <input className="crimson-input" type="number" name="amount" value={formData.amount} onChange={handleInputChange} required />
            </div> */}

            <div className="announcement-container">
                <label className="fiery-label" htmlFor="micAnnouncement">Mic Announcement</label>
                <input className="crimson-input" type="text" name="micAnnouncement" value={formData.micAnnouncement} onChange={handleInputChange} />
            </div>
            <div className="announcement-container">
                <label className="fiery-label" htmlFor="contact">Contact</label>
                <input className="crimson-input" type="text" name="contact" value={formData.contact} onChange={handleInputChange} />
            </div>
            {formData.packages === "VIP" && (
                <div>
                    <label className="fiery-label" htmlFor="screenAnnouncement">Screen Announcement</label>
                    <button
                        className="crimson-input"
                        onClick={() => document.getElementById("file-upload").click()}
                    >
                        Upload File
                    </button>
                    <input
                        className="crimson-input"
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setFileUpload(file);
                            console.log("File uploaded:", file);

                            if (file) {
                                // Update formData with the uploaded image
                                setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    uploadImg: file, // Add the image to formData
                                }));

                                // Show a popup for a brief period
                                setShowPopup(true);
                                setTimeout(() => setShowPopup(false), 3000);
                            }
                        }}
                    />
                </div>
             )}
            <div className="amount-container">
                <label className="fiery-label" htmlFor="totalAmount">Total Amount</label>
                <input className="crimson-input" type="number" name="totalAmount" value={formData.totalAmount} onChange={handleInputChange} required />
            </div>
            <div className="amount-container">
                <label className="fiery-label" htmlFor="paymentMethod">Payment Method</label>
                <select
                    className="crimson-input"
                    name="paymentMethod"
                    value={formData.paymentMethod}
                    onChange={handlePaymentMethodChange}
                >
                    <option value="">Select Payment Method</option>
                    <option value="Cash">Cash</option>
                    <option value="UPI">UPI</option>
                </select>
            </div>
            {formData.paymentMethod === 'UPI' && qrCodeUrl && (
                <div className="qr-code-container">
                    <p>Scan to pay via UPI:</p>
                    <img src={qrCodeUrl} alt="UPI QR Code" />
                </div>
            )}
            
            {/* Submit Button */}
            <button className="submit-btn" type="submit">Submit</button>
        </form>

    );
};

export default CashTransactionForm;
