class EventBus {
    constructor() {
      this.events = {};
    }
  
    on(event, listener) {
      if (!this.events[event]) {
        this.events[event] = [];
      }
      this.events[event].push(listener);
    }
  
    emit(event, data) {
        console.log("inside emit");
      if (this.events[event]) {
        console.log("inside if");
        this.events[event].forEach(listener => listener(data));
      }
    }
  
    off(event, listener) {
      if (this.events[event]) {
        this.events[event] = this.events[event].filter(l => l !== listener);
      }
    }
  }
  
  export default new EventBus();
  