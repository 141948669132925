import React from 'react';
import '../../App.css';
import '../Button.css'
import '../pages/DjHome.css';
import { DjTabs } from '../DjTabs';
import { Link } from 'react-router-dom';
import{ useEffect, useState } from 'react';
import { requestForToken } from '../firebase';

export default function DjHome() {
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
      setShowPopup(false); // Close the popup
    };

    const handleAddEventsButtonClick = (e) => {
        if (localStorage.getItem("role") !== "admin") {
            e.preventDefault(); // Prevent navigation
            setShowPopup(true);
        }
    };
    const handleUpdateProfileButtonClick = (e) => {
        if (localStorage.getItem("role") !== "admin" && localStorage.getItem("role") !== "dj") {
            e.preventDefault(); // Prevent navigation
            setShowPopup(true);
        }
    };

    const isAppleDevice = () => {
        return /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent);
    };

    const isInStandaloneMode = () => {
        return (window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone;
    };

    useEffect(() => {
        // console.log('first use Effect, isappledevice ' + isAppleDevice() + 'isPWA ' + isInStandaloneMode())
        if (!isAppleDevice() || isInStandaloneMode()) {
                Notification.requestPermission();
        }
    }, []);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const token = await requestForToken();
                if (token) {
                    //localStorage.setItem('fcmToken', token); // Store FCM token in local storage
                    console.log('FCM Token stored in local storage');
                }
            } catch (err) {
                console.error('Failed to fetch FCM token:', err);
            }
        };

        if (!localStorage.getItem('fcm')) {
            console.log(localStorage.getItem('DjId'))
            fetchToken(); // Fetch token only if it's not already in localStorage
        }
        else if (isAppleDevice() && isInStandaloneMode()) { 
            console.log('On Apple device PWA')
            fetchToken();
        }
    }, []);

    return (
        <div className='DjHome'>
            <div id="notification-root"></div>
            <div className='center-button'>
                <Link to='/djprofile' className='btn-medium left-button' onClick={handleUpdateProfileButtonClick}>
                    <button className='toolbar-button'>
                        UPDATE PROFILE
                    </button>
                </Link>
                <Link to='/eventdetails' className='btn-medium' onClick={handleAddEventsButtonClick}>
                    <button className='toolbar-button'>
                        ADD EVENTS
                    </button>
                </Link>
                <Link to='/showtransactions' className='btn-medium right-button'>
                    <button className='toolbar-button'>
                        SHOW TRANSACTIONS
                    </button>
                </Link>
            </div>
                {/* Popup component */}
            {showPopup && (
                <div className="upload-popup">
                <div className="popup-content">
                  <div className="hazard-icon">
                    <div className="triangle"></div>
                    <div className="exclamation">!</div>
                  </div>
                  <p>You do not have the required permissions to perform this action!</p>
                  <button className="popup-close-button" onClick={closePopup}>
                    Close
                </button>
                </div>
              </div>
              
            )}
            <DjTabs />
        </div>
    );
}
