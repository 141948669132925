import RegisterUser, { LoginUser } from "../components/services/UserService"

export default async function registerUserHelper(fName, lName, emailid, pass, phoneNum, gen, role, eventId, isSsologin = false) {
    var userModel = {
        firstName: fName,
        lastName: lName,
        email: emailid,
        phoneNumber: phoneNum,
        gender: gen,
        password: pass,
        role: role,
        eventId: eventId,
        isSsologin: isSsologin
    }
    var res = await RegisterUser(userModel);
    return res;
}

export async function googleLoginHelper(fName, lName, emailid, isrole = 'user', isSsologin = true) {
    var userModel = {
        firstName: fName,
        lastName: lName,
        email: emailid,
        isSsologin: isSsologin,
        role: isrole
    }
    var res = await RegisterUser(userModel);
    return res;
}

export async function loginUserHelper(email, pass) {
    var userModel = {
        email: email,
        password: pass
    }
    var res = await LoginUser(userModel);
    return res;
}

export async function loginWithOTPHelper(phoneNum, isSsologin = true) {
    var userModel = {
        firstName: 'guest',
        email: generateUniqueEmail(),
        phoneNumber: phoneNum,
        isSsologin: isSsologin,
        role: 'user'
    }
    var res = await RegisterUser(userModel);
    return res;
}

export async function waiterRegistrationHelper(name, phoneNum) {
    var userModel = {
        firstName: name,
        email: generateUniqueWaiterEmail(),
        phoneNumber: phoneNum,
        gender: 'male',
        password: '1234567890',
        isSsologin: false,
        role: 'staff'
    }
    var res = await RegisterUser(userModel);
    return res;
}
const generateUniqueEmail = () => {
    const uniqueId = Date.now(); // You can use Date.now() or any other method to generate a unique ID
    return `otp_${uniqueId}@otp.com`;
};
const generateUniqueWaiterEmail = () => {
    const uniqueId = Date.now(); // You can use Date.now() or any other method to generate a unique ID
    return `waiter_${uniqueId}@waiter.com`;
};